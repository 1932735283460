<template>
  <v-app>
    <v-main class="mt-10">
      <v-container>
        <v-row class="justify-center">
          <v-stepper v-model="e1" non-linear>
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1" editable>
                Solicitação
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2" editable>
                Disponibilidade / Confirmação
              </v-stepper-step>

              <!-- 
      <v-stepper-step editable step="3">
        Confirmação
        
      </v-stepper-step> -->
            </v-stepper-header>

            <v-stepper-items>
              <stepErp
                :profile="profile"
                :key="reload_one"
                :activate="e1"
                v-on:continue="Step"
                :clear="clear"
              />
              <stepAvailability
                :key="reload_two"
                :activate="e1"
                :form="form"
                v-on:continue="Step"
              />
              <!-- <stepCompleted v-if="e1" :activate='e1' :form='form'   v-on:continue='Step'/>  -->
            </v-stepper-items>
          </v-stepper>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
  <!--  -->
</template>

<script>
import Footer from "../layout/Footer";
import stepErp from "./step-erp";
import stepAvailability from "./step-availability";
// import stepCompleted from './step-completed.vue'

export default {
  name: "Agendamento",
  components: { stepErp, stepAvailability, Footer },
  data() {
    return {
      e1: 1,
      form: null,
      clear: false,
      reload_one: 1,
      reload_two: 2,
      profile: null
    };
  },
  watch: {
    clear: function(val) {
      if (val) {
        console.log("sd");
        this.reload_one += 1;
        this.reload_two = this.reload_one + 1;
      }
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      try {
        const response = await this.$http.get("/user/profile");
        this.profile = response.data;
      } catch (error) {
        localStorage.removeItem("token");

        this.$router.push("/");
      }
    },
    Step(i) {
      this.e1 = i.step;
      (this.form = i.form), (this.clear = i.clear);
    }
  }
};
</script>

<style></style>
