import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const dateNow = () => {
  return dayjs()
    .tz("America/Fortaleza")
    .local()
    .format("YYYY-MM-DD");
};

const dateFormat = date => {
  return dayjs(date)
    .tz("America/Fortaleza")
    .local()
    .format("DD/MM/YYYY");
};

const dateTime = datetime => {
  return dayjs(datetime)
    .tz("America/Fortaleza")
    .local()
    .format("DD/MM/YYYY HH:mm:ss");
};

export { dateNow, dateFormat, dateTime };
