var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v(" Abertura de OS ")]),_c('v-subheader',[_vm._v("Informações da solicitação")]),(_vm.overlay)?_c('v-progress-circular',{attrs:{"value":50}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","dark":"","icon":""},on:{"click":function($event){_vm.disable = !_vm.disable}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1),_c('v-card-text',[_c('form',[_c('v-row',[_c('v-col',{attrs:{"cols":"1","md":"3"}},[_c('v-text-field',{directives:[{name:"facade",rawName:"v-facade",value:('########'),expression:"'########'"}],attrs:{"label":"Nº Protocolo","outlined":"","error-messages":_vm.$v.protocol.$dirty && !_vm.$v.protocol.required
                    ? ['Informe o protocolo da solicitação']
                    : [],"required":"","hint":"Pressione enter","loading":_vm.overlay},on:{"input":function($event){return _vm.$v.protocol.$touch()},"blur":function($event){return _vm.$v.protocol.$touch()}},model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.service)?_c('v-select',{attrs:{"outlined":"","label":"Tipo da OS","disabled":_vm.disable,"item-text":"name","item-value":"id","items":_vm.service,"error-messages":_vm.$v.form.type_id.$dirty && !_vm.$v.form.type_id.required
                    ? ['Informe o tipo de OS']
                    : []},on:{"change":function($event){_vm.getServiceSubItem(), _vm.changeServiceType()},"input":function($event){return _vm.$v.form.type_id.$touch()},"blur":function($event){return _vm.$v.form.type_id.$touch()}},model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}}):_vm._e()],1),_c('v-col',[(_vm.subitems)?_c('v-select',{attrs:{"outlined":"","label":"Subtipo da OS","disabled":_vm.disable,"error-messages":_vm.$v.form.subtype_id.$dirty && !_vm.$v.form.subtype_id.required
                    ? ['Informe o subtipo da OS']
                    : [],"item-text":"name","item-value":"id","items":_vm.subitems},on:{"input":function($event){return _vm.$v.form.subtype_id.$touch()},"blur":function($event){return _vm.$v.form.subtype_id.$touch()},"change":function($event){return _vm.changeSubtype()}},model:{value:(_vm.form.subtype_id),callback:function ($$v) {_vm.$set(_vm.form, "subtype_id", $$v)},expression:"form.subtype_id"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.disable,"label":"Cliente","error-messages":_vm.$v.form.client.$dirty && !_vm.$v.form.client.required
                    ? ['Informe o nome do cliente']
                    : [],"outlined":"","required":""},on:{"input":function($event){return _vm.$v.form.client.$touch()},"blur":function($event){return _vm.$v.form.client.$touch()}},model:{value:(_vm.form.client),callback:function ($$v) {_vm.$set(_vm.form, "client", $$v)},expression:"form.client"}})],1),_c('v-col',{attrs:{"cols":"3","md":"2"}},[_c('v-text-field',{directives:[{name:"facade",rawName:"v-facade",value:(['(##) # ####-####', '(##) ####-####']),expression:"['(##) # ####-####', '(##) ####-####']"}],attrs:{"disabled":_vm.disable,"error-messages":_vm.$v.form.phone.$dirty && !_vm.$v.form.phone.required
                    ? ['Informe um numero de telefone.']
                    : [],"outlined":"","type":"tel","label":"Telefone"},on:{"input":function($event){return _vm.$v.form.phone.$touch()},"blur":function($event){return _vm.$v.form.phone.$touch()}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('v-col',{attrs:{"cols":"2","md":"2"}},[(_vm.tech)?_c('v-select',{attrs:{"label":"Tecnologia","outlined":"","items":_vm.tech,"error-messages":_vm.$v.form.tech.$dirty && !_vm.$v.form.tech.required
                    ? ['Informe a tecnologia do cliente']
                    : [],"required":""},on:{"input":function($event){return _vm.$v.form.tech.$touch()},"blur":function($event){return _vm.$v.form.tech.$touch()}},model:{value:(_vm.form.tech),callback:function ($$v) {_vm.$set(_vm.form, "tech", $$v)},expression:"form.tech"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2","md":"2"}},[(_vm.clienttype)?_c('v-select',{attrs:{"label":"Tipo de cliente","outlined":"","error-messages":_vm.$v.form.client_type.$dirty && !_vm.$v.form.client_type.required
                    ? ['Informe o tipo de cliente']
                    : [],"item-text":"type","item-value":"id","items":_vm.clienttype,"required":""},on:{"input":function($event){return _vm.$v.form.client_type.$touch()},"blur":function($event){return _vm.$v.form.client_type.$touch()}},model:{value:(_vm.form.client_type),callback:function ($$v) {_vm.$set(_vm.form, "client_type", $$v)},expression:"form.client_type"}}):_vm._e()],1)],1),_c('v-row')],1)])],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.emitContinue(2)}}},[_vm._v(" Avançar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }