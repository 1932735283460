<template>
  <v-row>
    <v-stepper-content step="1">
      <v-card flat>
        <v-toolbar color="primary" dense flat dark>
          <v-toolbar-title> Abertura de OS </v-toolbar-title>
          <v-subheader>Informações da solicitação</v-subheader>
          <v-progress-circular :value="50" v-if="overlay"></v-progress-circular>

          <v-spacer></v-spacer>
          <v-btn fab small @click="disable = !disable" dark icon>
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <form>
            <v-row>
              <v-col cols="1" md="3">
                <v-text-field
                  label="Nº Protocolo"
                  outlined
                  v-facade="'########'"
                  :error-messages="
                    $v.protocol.$dirty && !$v.protocol.required
                      ? ['Informe o protocolo da solicitação']
                      : []
                  "
                  @input="$v.protocol.$touch()"
                  @blur="$v.protocol.$touch()"
                  required
                  hint="Pressione enter"
                  :loading="overlay"
                  v-model="protocol"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  outlined
                  v-if="service"
                  label="Tipo da OS"
                  :disabled="disable"
                  item-text="name"
                  item-value="id"
                  :items="service"
                  @change="getServiceSubItem(), changeServiceType()"
                  v-model="form.type_id"
                  :error-messages="
                    $v.form.type_id.$dirty && !$v.form.type_id.required
                      ? ['Informe o tipo de OS']
                      : []
                  "
                  @input="$v.form.type_id.$touch()"
                  @blur="$v.form.type_id.$touch()"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  outlined
                  label="Subtipo da OS"
                  :disabled="disable"
                  v-if="subitems"
                  :error-messages="
                    $v.form.subtype_id.$dirty && !$v.form.subtype_id.required
                      ? ['Informe o subtipo da OS']
                      : []
                  "
                  @input="$v.form.subtype_id.$touch()"
                  @blur="$v.form.subtype_id.$touch()"
                  item-text="name"
                  item-value="id"
                  @change="changeSubtype()"
                  :items="subitems"
                  v-model="form.subtype_id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :disabled="disable"
                  v-model="form.client"
                  label="Cliente"
                  @input="$v.form.client.$touch()"
                  @blur="$v.form.client.$touch()"
                  :error-messages="
                    $v.form.client.$dirty && !$v.form.client.required
                      ? ['Informe o nome do cliente']
                      : []
                  "
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="2">
                <v-text-field
                  :disabled="disable"
                  :error-messages="
                    $v.form.phone.$dirty && !$v.form.phone.required
                      ? ['Informe um numero de telefone.']
                      : []
                  "
                  v-model="form.phone"
                  outlined
                  type="tel"
                  v-facade="['(##) # ####-####', '(##) ####-####']"
                  @input="$v.form.phone.$touch()"
                  @blur="$v.form.phone.$touch()"
                  label="Telefone"
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="2">
                <v-select
                  label="Tecnologia"
                  outlined
                  v-model="form.tech"
                  :items="tech"
                  :error-messages="
                    $v.form.tech.$dirty && !$v.form.tech.required
                      ? ['Informe a tecnologia do cliente']
                      : []
                  "
                  @input="$v.form.tech.$touch()"
                  @blur="$v.form.tech.$touch()"
                  v-if="tech"
                  required
                ></v-select>
              </v-col>

              <v-col cols="2" md="2">
                <v-select
                  label="Tipo de cliente"
                  outlined
                  :error-messages="
                    $v.form.client_type.$dirty && !$v.form.client_type.required
                      ? ['Informe o tipo de cliente']
                      : []
                  "
                  item-text="type"
                  item-value="id"
                  v-model="form.client_type"
                  :items="clienttype"
                  @input="$v.form.client_type.$touch()"
                  @blur="$v.form.client_type.$touch()"
                  v-if="clienttype"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </form>
        </v-card-text>
      </v-card>
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="emitContinue(2)" :disabled="$v.$invalid">
          Avançar
        </v-btn>
      </div>
    </v-stepper-content>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { debounce } from "../../utils/helpers/debounce";

export default {
  name: "stepErp",
  props: ["activate", "clear", "profile"],
  mixins: [validationMixin],

  validations: {
    protocol: { required },

    form: {
      tech: { required },
      type_id: { required },
      subtype_id: { required },
      phone: { required },
      client: { required },
      client_type: { required }
    }
  },
  data() {
    return {
      masked: false,
      e1: this.activate,
      tech: ["RÁDIO", "FIBRA", "CABO"],
      name: "",
      email: "",
      service: [],
      subitems: [],
      protocol: null,
      select: null,
      disable: true,
      checkbox: false,
      clienttype: null,
      overlay: false,
      form: {
        subtype_os: null,
        type_os: null
      }
    };
  },

  created() {
    this.getClientType();
    this.getServiceItem();
  },
  watch: {
    clear: function(val) {
      if (val) {
        this.form = {
          subtype_os: null,
          type_os: null
        };
      }
    },
    protocol: debounce(function() {
      this.getSolicitation();
    }, 1000)
  },
  methods: {
    changeSubtype() {
      this.subitems.map(s => {
        if (this.form.subtype_id === s.id) {
          this.form.subtype_os = s.name;
        }
      });
    },
    changeServiceType() {
      this.service.map(s => {
        if (this.form.type_id === s.id) {
          this.form.type_os = s.name;
        }
      });
    },
    emitContinue(step) {
      this.$emit("continue", {
        step: step,
        form: this.form
      });
    },
    async getServiceItem() {
      const response = await this.$http.get("/service-items");
      this.service = response.data;
    },
    async getServiceSubItem() {
      try {
        if (this.form.type_id) {
          const response = await this.$http.get(
            `/service-item/${this.form.type_id}/subitems`
          );
          this.subitems = response.data;
        }
      } catch (error) {
        this.$toast.error("Tipo de os e subtipo de os não informado!!");
      }
    },
    async getSolicitation() {
      try {
        this.overlay = true;
        const response = await this.$http.post("/solicitation", {
          protocol: this.protocol
        });

        this.form = Object.assign({}, response.data);
        this.getServiceSubItem();
        if (this.form.type_id === null && this.form.subtype_id === null) {
          this.$toast.error("Informe o tipo e o subtipo da OS");
        }
        this.overlay = false;
      } catch (error) {
        this.$toast.error(
          "Verifique o número do protocolo e tente novamente!!!"
        );
      }
    },
    async getClientType() {
      try {
        const response = await this.$http.get("/client-types");
        this.clienttype = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style></style>
