<template>
  <v-row>
    <v-stepper-content step="2">
      <v-card flat width="900">
        <v-toolbar color="primary" dense flat dark>
          <v-toolbar-title> Disponibilidade </v-toolbar-title>
          <v-subheader>Agendamento</v-subheader>
        </v-toolbar>
        <v-card-text>
          <form>
            <v-row>
              <v-col>
                <v-select
                  label="Cidade"
                  outlined
                  :items="city"
                  v-if="city"
                  v-model="citySelected"
                  item-text="name"
                  item-value="id"
                  :error-messages="
                    $v.citySelected.$dirty && !$v.citySelected.required
                      ? ['Selecione uma cidade.']
                      : []
                  "
                  @input="$v.citySelected.$touch()"
                  @blur="$v.citySelected.$touch()"
                  @change="getAddress()"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Bairro"
                  outlined
                  :error-messages="
                    $v.addressSelected.$dirty && !$v.addressSelected.required
                      ? ['Selecione um bairro.']
                      : []
                  "
                  @input="$v.addressSelected.$touch()"
                  @blur="$v.addressSelected.$touch()"
                  item-text="district"
                  item-value="id"
                  v-if="address"
                  v-model="addressSelected"
                  :items="address"
                  return-object
                  @change="getAddress()"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Área"
                  outlined
                  item-text="name"
                  item-value="id"
                  :items="area"
                  :error-messages="
                    $v.areaSelected.$dirty && !$v.areaSelected.required
                      ? ['Selecione uma área.']
                      : []
                  "
                  @input="$v.areaSelected.$touch()"
                  @blur="$v.areaSelected.$touch()"
                  v-if="area"
                  v-model="areaSelected"
                  readonly
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="8">
                <v-select
                  label="Data de agendamento"
                  outlined
                  v-model="offerSelected"
                  return-object
                  :item-text="item => dateformat(item.data_scheduling)"
                  item-value="data_scheduling"
                  :items="offers"
                  v-if="offers"
                  :error-messages="
                    $v.offerSelected.$dirty && !$v.offerSelected.required
                      ? ['Selecione uma data.']
                      : []
                  "
                  @input="$v.offerSelected.$touch()"
                  @blur="$v.offerSelected.$touch()"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Período"
                  outlined
                  :items="periods"
                  v-if="periods"
                  :error-messages="
                    $v.periodSelected.$dirty && !$v.periodSelected.required
                      ? ['Selecione um período.']
                      : []
                  "
                  @input="$v.periodSelected.$touch()"
                  @blur="$v.periodSelected.$touch()"
                  v-model="periodSelected"
                  required
                  @change="findOffer()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Criterio"
                  outlined
                  clearable
                  v-model="availability.criteriaOne"
                  item-text="name"
                  :error-messages="
                    $v.availability.criteriaOne.$dirty &&
                    !$v.availability.criteriaOne.required
                      ? ['Selecione um criterio.']
                      : []
                  "
                  @input="$v.availability.criteriaOne.$touch()"
                  @blur="$v.availability.criteriaOne.$touch()"
                  item-value="id"
                  v-if="criteria"
                  :items="criteria"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Criterio"
                  outlined
                  clearable
                  v-model="availability.criteriaTwo"
                  item-text="name"
                  item-value="id"
                  :items="criteria"
                  v-if="criteria"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Criterio"
                  outlined
                  clearable
                  v-model="availability.criteriaThree"
                  item-text="name"
                  item-value="id"
                  :items="criteria"
                  v-if="criteria"
                ></v-select>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
      </v-card>
      <div class="d-flex">
        <v-btn text @click="emitContinue(1)"> Voltar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit()" :disabled="$v.$invalid">
          Confirmar
        </v-btn>
      </div>
    </v-stepper-content>
  </v-row>
</template>

<script>
import { dateFormat } from "../../utils/helpers/dateHelper";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "stepAvailability",
  props: ["activate", "form"],
  mixins: [validationMixin],
  validations: {
    citySelected: { required },
    addressSelected: { required },
    offerSelected: { required },
    areaSelected: { required },
    periodSelected: { required },
    availability: {
      criteriaOne: { required }
    }
  },
  data() {
    return {
      e1: this.activate,
      confirm: true,
      city: [],
      area: [],
      availability: {},
      offerSelected: null,
      address: [],
      offers: [],
      periods: [],
      periodSelected: [],
      criteriaForm: 3,
      addressSelected: null,
      areaSelected: null,
      citySelected: null,
      criteria: [],
      offerAvailability: null,
      criteriaOne: null,
      criteriaTwo: 0,
      criteriaThree: 0,
      scheduling: {
        criterias: []
      }
    };
  },
  created() {
    this.getCity();
    this.getArea();
    this.getCriteria();
  },
  watch: {
    form: function(val) {
      this.availability = val;
    },
    offerSelected: function() {
      this.getPeriods();
    },
    criteriaOne: function() {
      return (this.confirm = true);
    },
    citySelected: function() {
      if (this.areaSelected) return (this.areaSelected = null);
    },
    areaSelected: function() {
      this.getOffer();
    },
    addressSelected: function(val) {
      this.areaSelected = val.area;
    }
  },
  methods: {
    dateformat(date) {
      return dateFormat(date);
    },
    emitContinue(step) {
      this.$emit("continue", {
        step: step,
        form: this.availability
      });
    },

    async getCity() {
      const response = await this.$http.get("/city");
      this.city = response.data;
    },
    async getOffer() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.type_id,
        area: this.areaSelected
      });
      console.log(response.data);
      this.offers = response.data.offer;
    },
    async getPeriods() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.type_id,
        area: this.areaSelected,
        data_scheduling: this.offerSelected.data_scheduling
      });
      console.log(response.data);
      this.periods = response.data.periods;
    },
    async getCriteria() {
      const response = await this.$http.get("/criteria");
      this.criteria = response.data;
    },
    async getArea() {
      const response = await this.$http.get("/area");
      this.area = response.data;
    },
    async findOffer() {
      const response = await this.$http.post("/offer/areas", {
        ref_type: this.form.type_id,
        area: this.areaSelected,
        data_scheduling: this.offerSelected.data_scheduling,
        period: this.periodSelected
      });

      this.offerAvailability = response.data.offer[0];
    },

    async getAddress() {
      const response = await this.$http.post("/address/criterias", {
        city: this.citySelected,
        deleted: false
      });

      this.address = response.data;
    },
    async submit() {
      try {
        this.availability.ref_address = this.addressSelected.id;
        this.availability.ref_offer = this.offerAvailability.id;
        delete this.availability.type_id;
        delete this.availability.type_os;
        await this.$http.post("/scheduling", this.availability);

        this.$toast.success("Agendamento realizado com sucesso!!!");
        this.$router.push("/agendamentos/listar");
      } catch (error) {
        if (error.response.status === 404) {
          this.$toast.error(
            "Não foi possivel realizar o agendamento, o período informado não está mais disponível."
          );
          this.periods = [];
          this.getOffer();
          this.getPeriods();
        }
      }
    }
  }
};
</script>

<style></style>
